import React from "react"
import { Link } from "react-router-dom"
import Styles from "./Footer.module.css"
import bg from "../assets/siluetti.png"
import logo from "../assets/business-tampere-logo.png"

const Footer = () => (
  <footer
    className={Styles.footerWrapper}
    style={{
      backgroundImage: `url(${bg})`,
    }}
    id="footer"
  >
    <div className={Styles.footerContainer}>
      <div className={[Styles.logo, Styles.footerSection].join(" ")}>
        <img src={logo} alt="logo" className={Styles.logo} />
        <div className={Styles.copyright}>
          <p className={Styles.ContactInfoLine}>
            Tampereen kaupunkiseudun virallinen yrityshakemisto
          </p>
          <p className={Styles.ContactInfoLine}>© Business Tampere</p>
        </div>
      </div>
      <div className={[Styles.contactInfo, Styles.footerSection].join(" ")}>
        <div className={Styles.businessInfo}>
          <strong className={Styles.businessInfoLine}>Business Tampere</strong>
          <p className={Styles.businessInfoLine}>
            c/o Tampereen kaupunkiseudun elinkeino- ja kehitysyhtiö Tredea Oy
          </p>
        </div>
        <p className={Styles.ContactInfoLine}>
          Verkko-osoite:{" "}
          <a className={Styles.ContactInfoLink} href="https://www.businesstampere.fi">
            https://www.businesstampere.fi
          </a>
        </p>
        <p className={Styles.ContactInfoLine}>
          Sähköposti:{" "}
          <a className={Styles.ContactInfoLink} href="mailto:info@businesstampere.com">
            info@businesstampere.com
          </a>
        </p>
        <p className={Styles.ContactInfoLine}>Käyntiosoite: Kelloportinkatu 1 B, 33100 Tampere</p>
        <p className={Styles.ContactInfoLine}>Postiosoite: Kelloportinkatu 1 C, 33100 Tampere</p>
        <p className={Styles.ContactInfoLine}>Y-tunnus: 2252888-5</p>
      </div>
      <div className={[Styles.links, Styles.footerSection].join(" ")}>
        <Link className={Styles.link} to="/tietosuojaseloste">
          Tietosuojaseloste
        </Link>
        <Link className={Styles.link} to="/tietoa-palvelusta">
          Tietoa palvelusta
        </Link>
        <Link className={Styles.link} to="/usein-kysytyt-kysymykset">
          FAQ
        </Link>
      </div>
    </div>
  </footer>
)

export default Footer
