import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { VictoryPie, VictoryLabel, VictoryContainer } from "victory"
import LoadingSpinner from "../../LoadingSpinner"
import DashboardCard from "../../DashboardCards/DashboardCard"
import Styles from "./CompanyTypes.module.css"
import colorMap from "../colorMap"
import NotAvailable from "../../NotAvailable"
import { CHART_ANIM } from "../../../util/constants"
import LoadingOverlay from "../../LoadingOverlay"
import InfoModal from "../../InfoModal"
import { ChartTable } from "../../ChartTable"

class CompanyTypes extends Component {
  state = {
    labelText: "",
  }

  renderLoaderOverlay = () => {
    const { loading, companyTypes } = this.props
    if (loading && companyTypes) {
      return (
        <div className={Styles.loadingOverlay}>
          <LoadingSpinner loading={loading} />
        </div>
      )
    }
  }

  renderCompanyTypes() {
    const { labelText } = this.state
    const { loading, companyTypes } = this.props

    if (!companyTypes) {
      return null
    }

    if (!companyTypes.total.length) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }
    // counts total amount of companies to calculate pie percentage
    const totalCompanies = companyTypes.total.reduce((total, { amount }) => total + amount, 0)

    const data = [...companyTypes.total]
      .sort((a, b) => a.amount - b.amount)
      .map(({ amount: y, yhtiomuoto }, x) => ({
        x,
        y,
        label: `${((y / totalCompanies) * 100).toFixed(2)}%`,
        yhtiomuoto,
        color: colorMap[x],
      }))

    return (
      <div className={Styles.container}>
        <LoadingOverlay visible={Boolean(loading && companyTypes)} />
        <VictoryContainer width={400} height={350}>
          <VictoryPie
            padding={{ top: 20, right: 50, bottom: 50, left: 50 }}
            animate={CHART_ANIM}
            standalone={false}
            data={data}
            innerRadius={({ active }) => (active ? 100 : 130)}
            radius={({ active }) => (active ? 140 : 110)}
            style={{
              labels: { display: "none" },
              data: {
                fill: ({ datum }) => datum.color,
              },
            }}
            events={[
              {
                childName: "all",
                target: "data",
                eventHandlers: {
                  onMouseOver: (_, { datum }) => {
                    this.setState({
                      labelText: `${datum.yhtiomuoto.split(" ").join("\n")}\n${datum.label}`,
                    })
                    return [
                      { target: "labels", mutation: () => ({ active: true }) },
                      { target: "data", mutation: () => ({ active: true }) },
                    ]
                  },
                  onMouseOut: () => {
                    this.setState({ labelText: "" })
                    return [
                      { target: "labels", mutation: () => ({ active: false }) },
                      { target: "data", mutation: () => ({ active: false }) },
                    ]
                  },
                },
              },
            ]}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 18, fontFamily: "Chivo", maxWidth: "33%" }}
            x={200}
            y={175}
            text={labelText}
          />
        </VictoryContainer>
        <div className={Styles.companyTypesWrapper}>
          <div className={Styles.companyTypes}>
            {data.map(({ yhtiomuoto, color }) => (
              <div className={Styles.companyWrapper} key={yhtiomuoto}>
                <div className={Styles.square} style={{ backgroundColor: color }} />
                <span>{yhtiomuoto}</span>
              </div>
            ))}
          </div>
        </div>
        <ChartTable
          data={companyTypes && companyTypes.total}
          columns={this.getTableColumns()}
          title="Yhtiömuodot"
        >
          {this.renderInfo()}
        </ChartTable>
      </div>
    )
  }

  renderContent = () => {
    const { loading, companyTypes } = this.props
    if (loading && !companyTypes) {
      return <LoadingSpinner loading={loading} />
    }
    return this.renderCompanyTypes()
  }

  getTableColumns = () => {
    return [
      {
        id: "yhtiomuoto",
        heading: "Yhtiömuoto",
      },
      {
        id: "amount",
        heading: "Lukumäärä",
      },
    ]
  }

  renderInfo = () => (
    <p>
      Yhtiömuodot näyttää kunnittain ja toimialoittain eri yhtiömuotojen määrän suhteutettuna
      yhtiöiden kokonaismäärään.
      <br />
      <br />
      Tietolähde: Profinder
    </p>
  )
  render() {
    return (
      <DashboardCard
        title="Yhtiömuodot"
        renderModal={<InfoModal position="left">{this.renderInfo()}</InfoModal>}
      >
        {this.renderContent()}
      </DashboardCard>
    )
  }
}
CompanyTypes.defaultProps = {
  companyTypes: null,
}

CompanyTypes.propTypes = {
  companyTypes: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    companyTypes: state.chartStatistics.data.companyTypes,
  }
}
export default connect(mapStateToProps)(CompanyTypes)
