import { Api } from "../services"
import { REVENUE_VALUES, REVENUE_LIST_YEAR } from "../util/constants"

export const FETCH_TOP10_REVENUE = "FETCH_TOP10_REVENUE"
export const FETCH_TOP10_REVENUE_OK = "FETCH_TOP10_REVENUE_OK"
export const FETCH_TOP10_REVENUE_ERROR = "FETCH_TOP10_REVENUE_ERROR"
export const FETCH_TOP10_REVENUE_CHANGE_PARAMS = "FETCH_TOP10_REVENUE_CHANGE_PARAMS"
export const FETCH_TOP10_REVENUE_CLEAR_PARAMS = "FETCH_TOP10_REVENUE_CLEAR_PARAMS"

export const fetchTop10 = params => async dispatch => {
  const {
    minRevenue = 1000000,
    maxRevenue = 100000000000000,
    region = "%",
    industry = "%",
    limit = 10,
    direction = "DESC",
    year = REVENUE_LIST_YEAR,
  } = params
  dispatch({ type: FETCH_TOP10_REVENUE })
  const response = await Api.getRevenueList({
    minRevenue,
    maxRevenue,
    region,
    industry,
    limit,
    direction,
    year: year !== 0 ? year : REVENUE_LIST_YEAR,
  })
  if (response.ok) {
    dispatch({
      type: FETCH_TOP10_REVENUE_OK,
      data: response.data,
    })
  } else {
    dispatch({
      type: FETCH_TOP10_REVENUE_ERROR,
      error: response.data,
    })
  }
}

export const changeParams = params => async dispatch => {
  const { revenue, region, industry, year } = params
  const rev = revenue === "" ? "1" : revenue

  dispatch({ type: FETCH_TOP10_REVENUE_CHANGE_PARAMS, data: params })
  dispatch(
    fetchTop10({
      minRevenue: REVENUE_VALUES[rev - 1].min,
      maxRevenue: REVENUE_VALUES[rev - 1].max,
      region,
      industry,
      year,
    })
  )
}

export const clearParams = filters => async dispatch => {
  dispatch({ type: FETCH_TOP10_REVENUE_CLEAR_PARAMS })
  dispatch(fetchTop10({ ...filters }))
}
