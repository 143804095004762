import React, { Component } from "react"
import PropTypes from "prop-types"
import LoadingSpinner from "../../LoadingSpinner"
import { formatMoney } from "../../../util/string"
import DashboardCard from "../../DashboardCards/DashboardCard"
import Styles from "./RdiFunding.module.css"
import RdiFundingSelector from "./RdiFundingSelector"
import InfoModal from "../../InfoModal"
import { ButtonLink } from "../../ButtonLink"

class RdiFunding extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selector: "Yhteensä",
    }
  }

  onChangeSelect = ({ label: selector }) => {
    this.setState({
      selector,
    })
  }

  renderFunding = () => {
    const {
      firstYearFunding,
      secondYearFunding,
      firstYearLoans,
      secondYearLoans,
      currentYear,
    } = this.props
    const { selector } = this.state
    let firstValue = 0
    let secondValue = 0

    if (selector === "Yhteensä") {
      firstValue = Number(firstYearFunding) + Number(firstYearLoans)
      secondValue = Number(secondYearFunding) + Number(secondYearLoans)
    } else if (selector === "Avustukset") {
      firstValue = firstYearFunding
      secondValue = secondYearFunding
    } else {
      firstValue = firstYearLoans
      secondValue = secondYearLoans
    }

    return (
      <div className={Styles.wrapper}>
        <div className={Styles.fundingContainer}>
          <div className={Styles.secondYearWrapper}>
            <p className={Styles.heading}>{`Myönnetty ${currentYear}`}</p>
            <p className={Styles.funding}>{formatMoney(secondValue)}</p>
          </div>
          <div className={Styles.firstYearWrapper}>
            <p className={Styles.heading}>{`Myönnetty ${currentYear - 1}`}</p>
            <p className={Styles.funding}>{formatMoney(firstValue)}</p>
          </div>
        </div>
        <div className={Styles.buttonWrapper}>
          <ButtonLink
            href="https://tietopankki.businessfinland.fi/anonymous/extensions/MyonnettyRahoitus/MyonnettyRahoitus.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkki Business Finlandiin
          </ButtonLink>
        </div>
      </div>
    )
  }

  renderContent = () => {
    const { loading } = this.props
    if (loading) {
      return <LoadingSpinner loading={loading} />
    }
    return this.renderFunding()
  }

  render() {
    return (
      <DashboardCard
        title="Business finlandin myöntämä TKI-rahoitus"
        renderModal={
          <InfoModal position="left">
            <p>
              Business Finlandin myöntämät lainat ja avustukset tutkimus-, kehittämis- ja
              innovaatiotoimintaan.
              <br />
              <br />
              Tietolähde: Business Finland
            </p>
          </InfoModal>
        }
      >
        <div className={Styles.selectContainer}>
          <RdiFundingSelector
            className="dropdown"
            onChange={this.onChangeSelect}
            value="Yhteensä"
          />
        </div>
        {this.renderContent()}
      </DashboardCard>
    )
  }
}

RdiFunding.propTypes = {
  loading: PropTypes.bool.isRequired,
  firstYearFunding: PropTypes.number.isRequired,
  secondYearFunding: PropTypes.number.isRequired,
  firstYearLoans: PropTypes.number.isRequired,
  secondYearLoans: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
}

export default RdiFunding
