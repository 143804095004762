import React from "react"
import Search from "../components/Search"
import Styles from "./Header.module.css"
import bg from "../assets/haun-bg.jpg"
import icon from "../assets/koti.svg"
import graph from "../assets/graph.svg"

const Header = () => (
  <header>
    <div className={Styles.topBar}>
      <div className={Styles.topBarContent}>
        <a href="/" className={Styles.linkLeft}>
          <img className={Styles.topBarContentImage} src={icon} alt="home-icon" />
          Kotisivulle
        </a>
        <a className={Styles.linkRight} href="https://businesstampere.fi">
          businesstampere.fi
        </a>
      </div>
    </div>
    {/*
      <div className={Styles.problemTip}>
        <h1>Tiedote: Sivusto näyttää virheellisiä tietoja, vikaa korjataan.</h1>
      </div>
    */}
    <div
      className={Styles.hero}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={Styles.imageOverlay} />
      <a href="/" className={Styles.headingsContainer}>
        <img className={Styles.graphImage} src={graph} alt="graph-logo" />
        <div className={Styles.headings}>
          <span className={Styles.headingTop}>Ajantasainen</span>
          <h1 className={Styles.headingYritystieto}>Yritystieto</h1>
          <h2 className={Styles.headingBusinessTampere}>Business Tampere</h2>
          <span className={Styles.headingBottom}>
            Business Tampereen yritystietopalvelu kerää yhteen ajantasaiset tiedot Tampereen
            kaupunkiseudun yrityksistä.
          </span>
        </div>
      </a>
    </div>
    <div className={[Styles.pageContent, Styles.containerFluid].join(" ")}>
      <Search />
    </div>
  </header>
)

export default Header
