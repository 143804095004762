import { Api } from "../services"

export const FETCH_BRANCH = "FETCH_BRANCH"
export const FETCH_BRANCH_OK = "FETCH_BRANCH_OK"
export const FETCH_BRANCH_ERROR = "FETCH_BRANCH_ERROR"

export const fetchBranch = branchId => async dispatch => {
  dispatch({ type: FETCH_BRANCH })

  const response = await Api.getBranch(branchId)
  if (response.ok) {
    dispatch({
      type: FETCH_BRANCH_OK,
      data: response.data,
    })
  } else {
    dispatch({
      type: FETCH_BRANCH_ERROR,
      error: response.data,
    })
  }
}
