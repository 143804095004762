import React, { Component } from "react"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from "victory"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import LoadingSpinner from "../../LoadingSpinner"
import DashboardCard from "../../DashboardCards/DashboardCard"
import Styles from "./Turnover.module.css"
import colorMap from "../colorMap"
import NotAvailable from "../../NotAvailable"
import { revenueToNumber } from "../../../util/revenueToNumber"
import { CHART_ANIM } from "../../../util/constants"
import LoadingOverlay from "../../LoadingOverlay"
import InfoModal from "../../InfoModal"
import { ChartTable } from "../../ChartTable"

class Turnover extends Component {
  getTableColumns = () => {
    return [
      {
        id: "revenue",
        heading: "Liikevaihto",
      },
      {
        id: "y",
        heading: "Yritysten määrä",
      },
    ]
  }
  renderTurnover() {
    const { loading, companiesByRevenue } = this.props

    if (!companiesByRevenue) {
      return null
    }

    if (!companiesByRevenue.total.length) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }

    const data = [...companiesByRevenue.total]
      .filter(({ revenue }) => revenue !== "0")
      .sort((a, b) => b.amount - a.amount)
      .map(({ amount: y, revenue }, x) => ({
        x: x + 1,
        y,
        revenue,
        color: colorMap[x],
      }))
    return (
      <div className={Styles.container}>
        <LoadingOverlay visible={Boolean(loading && companiesByRevenue)} />
        <VictoryChart
          height={350}
          width={400}
          domainPadding={25}
          padding={{ top: 25, right: 50, bottom: 25, left: 57.5 }}
          style={{
            parent: {
              height: "auto",
              border: "none",
              padding: "0 2rem",
            },
          }}
        >
          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: "#E7E7E7" },
              tickLabels: { fill: "none" },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              grid: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              axis: { stroke: "transparent" },
            }}
            tickCount={9}
            animate={CHART_ANIM}
          />
          <VictoryBar
            animate={CHART_ANIM}
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                flyoutStyle={{ stroke: "grey", strokeWidth: 0.5, fill: "white" }}
                pointerLength={16}
                centerOffset={{ x: 20 }}
              />
            }
            labels={({ datum }) => datum.y}
            barWidth={16}
            style={{
              data: {
                fill: ({ datum }) => datum.color,
              },
            }}
            data={data}
          />
        </VictoryChart>
        <div className={Styles.turnoverContainer}>
          <div className={Styles.turnover}>
            {data.map(({ revenue, color }) => (
              <div className={Styles.turnoverWrapper} key={revenue}>
                <div className={Styles.square} style={{ backgroundColor: color }} />
                <span>{revenue}</span>
              </div>
            ))}
          </div>
        </div>
        <ChartTable
          data={data}
          columns={this.getTableColumns()}
          className={Styles.chartButton}
          title="Yritykset liikevaihdon mukaan"
        >
          {this.renderInfo()}
        </ChartTable>
      </div>
    )
  }

  renderInfo = () => (
    <p>
      Kuvaaja esittää kunnittain, tilinpäätösvuosittain ja toimialoittain yhtiöiden määrät eri
      liikevaihtoluokissa.
      <br />
      Esivalittuna kuvaaja käyttää kunkin yrityksen kohdalla viimeisimpiä järjestelmästä löytyviä
      taloustietoja.
      <br />
      <br />
      Tietolähteet: Profinder, Alma Talent
    </p>
  )
  renderContent = () => {
    const { loading, companiesByRevenue } = this.props
    if (loading && !companiesByRevenue) {
      return <LoadingSpinner loading={loading} />
    }
    return this.renderTurnover()
  }

  render() {
    return (
      <DashboardCard
        title="Liikevaihdon mukaan"
        renderModal={<InfoModal position="left">{this.renderInfo()}</InfoModal>}
      >
        {this.renderContent()}
      </DashboardCard>
    )
  }
}
Turnover.defaultProps = {
  companiesByRevenue: null,
}

Turnover.propTypes = {
  companiesByRevenue: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    companiesByRevenue: state.chartStatistics.data.companiesByRevenue,
  }
}
export default connect(mapStateToProps)(Turnover)
