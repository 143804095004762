import { Api } from "../services"

export const FETCH_INDUSTRIES = "FETCH_INDUSTRIES"
export const FETCH_INDUSTRIES_OK = "FETCH_INDUSTRIES_OK"
export const FETCH_INDUSTRIES_ERROR = "FETCH_INDUSTRIES_ERROR"

export const fetchIndustries = () => async dispatch => {
  dispatch({ type: FETCH_INDUSTRIES })

  const response = await Api.getIndustries()

  if (response.ok) {
    const data = response.data
      .filter(industry => industry.length > 0)
      .map(industry => ({ label: industry, value: industry }))
    dispatch({
      type: FETCH_INDUSTRIES_OK,
      data,
    })
  } else {
    dispatch({
      type: FETCH_INDUSTRIES_ERROR,
      error: response.data,
    })
  }
}
