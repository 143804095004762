import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { fetchBranch } from "../actions/branchActions"
import { BranchInfo, Map } from "../components/CompanyInfo"
import LoadingSpinner from "../components/LoadingSpinner"
import Styles from "./Company.module.css"
import { Seo } from "../components/Seo"

class Branch extends Component {
  static contextTypes = {
    router: () => true, // replace with PropTypes.object if you use them
  }

  componentDidMount() {
    const { match, getBranch } = this.props
    const { branchId } = match.params
    if (branchId) getBranch(branchId)
    window.scrollTo(0, 0)
  }

  renderMap = branch => {
    if (!branch.latitude || !branch.longitude) return null
    return <Map markers={[branch]} showPopup />
  }

  renderBranch = () => {
    const { branch } = this.props
    if (Object.keys(branch).length === 0) {
      return <h1 className={Styles.notFound}>Sivutoimipaikkaa ei löydy</h1>
    }
    return (
      <div className={Styles.companyPage}>
        <BranchInfo branch={branch} />
        {this.renderMap(branch)}
      </div>
    )
  }

  renderBackButton = () => {
    const { location, history } = this.props
    if (location.state && location.state.prevPath === "/") {
      return (
        <div className={Styles.backButton}>
          <Link
            to={{
              pathname: "/",
              state: { prevPath: location.pathname },
            }}
          >
            ← Takaisin etusivulle
          </Link>
        </div>
      )
    }
    if (
      (location.state && location.state.prevPath.includes("/haku/")) ||
      (location.state && location.state.prevPath.includes("/top500/"))
    ) {
      return (
        <div className={Styles.backButton}>
          <button type="button" onClick={history.goBack}>
            ← Takaisin hakuun
          </button>
        </div>
      )
    }
    if (document.referrer.includes(window.location.origin)) {
      return (
        <div className={Styles.backButton}>
          <button type="button" onClick={history.goBack}>
            ← Takaisin
          </button>
        </div>
      )
    }
    return null
  }

  render() {
    const { loading, branch } = this.props

    return (
      <div className={[Styles.pageContent, Styles.companyScreen].join(" ")}>
        {loading ? (
          <div className={Styles.loadingContainer}>
            <LoadingSpinner loading={loading} />
          </div>
        ) : (
          <div>
            {this.renderBackButton()}
            {this.renderBranch()}
          </div>
        )}
        <Seo title={`Sivutoimipaikka "${branch.virallinen_nimi}"`} />
      </div>
    )
  }
}

Branch.propTypes = {
  loading: PropTypes.bool.isRequired,
  branch: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  getBranch: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

Branch.contextTypes = {
  router: PropTypes.shape(),
}

const mapStateToProps = state => ({
  branch: state.branch.data,
  loading: state.branch.loading,
})

const mapDispatchToProps = dispatch => ({
  getBranch: businessId => dispatch(fetchBranch(businessId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Branch))
