import { Api } from "../services"

export const FETCH_FUNDING = "FETCH_FUNDING"
export const FETCH_FUNDING_OK = "FETCH_FUNDING_OK"
export const FETCH_FUNDING_ERROR = "FETCH_FUNDING_ERROR"
export const CHANGE_FUNDING_PARAMS = "CHANGE_FUNDING_PARAMS"
export const CLEAR_FUNDING_PARAMS = "CLEAR_FUNDING_PARAMS"

export const fetchFunding = (year, region, industry) => async dispatch => {
  dispatch({ type: FETCH_FUNDING })

  const previousYearData = await Api.getFunding(region, industry, year - 1)
  const yearData = await Api.getFunding(region, industry, year)

  if (previousYearData.ok && yearData.ok) {
    dispatch({
      type: FETCH_FUNDING_OK,
      firstYearFunding: previousYearData.data.fundings.total,
      firstYearLoans: previousYearData.data.loans.total,
      secondYearFunding: yearData.data.fundings.total,
      secondYearLoans: yearData.data.loans.total,
    })
  } else {
    dispatch({
      type: FETCH_FUNDING_ERROR,
      error: previousYearData.data,
    })
  }
}

export const changeFundingParams = ({ year, region, industry }) => async dispatch => {
  dispatch({ type: CHANGE_FUNDING_PARAMS, data: { year, region, industry } })
  dispatch(fetchFunding(year, region, industry))
}

export const clearFundingParams = ({ year }) => async dispatch => {
  dispatch({ type: CLEAR_FUNDING_PARAMS })
  dispatch(fetchFunding(year))
}
