import { Api } from "../services"

export const FETCH_REGION_STATISTICS = "FETCH_REGION_STATISTICS"
export const FETCH_REGION_STATISTICS_OK = "FETCH_REGION_STATISTICS_OK"
export const FETCH_REGION_STATISTICS_ERROR = "FETCH_REGION_STATISTICS_ERROR"

export const fetchRegionStatistics = (region, industry, year) => async dispatch => {
  dispatch({ type: FETCH_REGION_STATISTICS })

  const response = await Api.getRegionStatistics(
    region,
    industry,
    year !== 0 ? year : new Date().getFullYear()
  )
  if (response.ok) {
    dispatch({
      type: FETCH_REGION_STATISTICS_OK,
      data: response.data,
    })
  } else {
    dispatch({
      type: FETCH_REGION_STATISTICS_ERROR,
      error: response.data,
    })
  }
}
