import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import "./MultiSelector.css"
import ReactFocusLock, { AutoFocusInside } from "react-focus-lock"
import chevron from "../../assets/nuoli-alas-white.svg"

export default class MultiSelector extends PureComponent {
  trigger = null
  buttonText = () => {
    const { text, filters } = this.props
    if (Array.isArray(filters)) {
      return (
        <span className="button-text">
          {text}
          <p className={`amount ${filters.length > 0 ? "some" : "none"}`}>{filters.length}</p>
        </span>
      )
    }
    return (
      <span className="button-text">
        {text}
        <p
          className={`amount ${
            !Object.values(filters).every(x => x === null || x === "") ? "some" : "none"
          }`}
        >
          {1}
        </p>
      </span>
    )
  }

  handleSelectAll = () => {
    const { options, handleChange, target } = this.props
    handleChange(options, target, true)
  }

  handleCancel = filter => {
    this.props.clearFilters(filter)
    this.trigger.focus()
  }

  render() {
    const { visible, toggleSelector, disabled, children, filter, options } = this.props
    return (
      <div className="selector-wrapper">
        <button
          ref={e => (this.trigger = e)}
          className={`selector-button ${String(visible)}`}
          type="button"
          aria-haspopup="true"
          aria-expanded={visible}
          onClick={() => {
            toggleSelector(filter)
          }}
          disabled={disabled}
        >
          {this.buttonText()}
          <img src={chevron} alt="chevron" className="chevron" />
        </button>
        {visible && (
          <div className="modal-wrapper" role="dialog">
            <ReactFocusLock>
              <AutoFocusInside>
                <div className="modal">
                  {children}
                  <div className="bottom">
                    <button
                      className="confirm-button"
                      type="button"
                      onClick={() => {
                        toggleSelector(filter)
                      }}
                    >
                      OK
                    </button>
                    {options && (
                      <button
                        className="select-all-button"
                        type="button"
                        onClick={this.handleSelectAll}
                      >
                        Valitse kaikki
                      </button>
                    )}
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => this.handleCancel(filter)}
                    >
                      Tyhjennä valinnat
                    </button>
                  </div>
                </div>
              </AutoFocusInside>
            </ReactFocusLock>
          </div>
        )}
      </div>
    )
  }
}

MultiSelector.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleSelector: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  filters: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()])
    .isRequired,
  filter: PropTypes.string.isRequired,
  options: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()],
    undefined
  ),
  handleChange: PropTypes.func,
  target: PropTypes.string,
}

MultiSelector.defaultProps = {
  disabled: false,
  handleChange: () => {},
  options: null,
  target: "",
}
