import React, { useState } from "react"
import PropTypes from "prop-types"
import classes from "./InfoModal.module.css"
import icon from "../assets/info.svg"
import invertedIcon from "../assets/info-white.svg"

const InfoModal = ({ children, position, inverted, className }) => {
  const [modalOpen, setModalOpen] = useState()

  const openModal = () => setModalOpen(true)

  const closeModal = () => setModalOpen(false)

  return (
    <div className={className ? [className, classes.infoModal] : classes.infoModal}>
      <button
        onMouseEnter={openModal}
        onMouseLeave={closeModal}
        onFocus={openModal}
        onBlur={closeModal}
        className={classes.borderlessButton}
        aria-label="Näytä lisätietoa"
      >
        <img
          src={inverted ? invertedIcon : icon}
          alt="icon"
          className={classes.button}
          role="presentation"
        />
      </button>
      {modalOpen && (
        <div role="dialog" className={classes[`modal-${position}`]}>
          {children}
        </div>
      )}
    </div>
  )
}
InfoModal.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  inverted: PropTypes.bool,
  className: PropTypes.string,
}

InfoModal.defaultProps = {
  position: "left",
  inverted: false,
  className: null,
}

export default InfoModal
