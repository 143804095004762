import { Api } from "../services"

export const FETCH_COMPANY = "FETCH_COMPANY"
export const FETCH_COMPANY_OK = "FETCH_COMPANY_OK"
export const FETCH_COMPANY_ERROR = "FETCH_COMPANY_ERROR"

export const fetchCompany = businessId => async dispatch => {
  dispatch({ type: FETCH_COMPANY })

  const response = await Api.getCompany(businessId)
  if (response.ok) {
    dispatch({
      type: FETCH_COMPANY_OK,
      data: response.data,
    })
  } else {
    dispatch({
      type: FETCH_COMPANY_ERROR,
      error: response.data,
    })
  }
}
