import apisauce from 'apisauce'
// import fileDownload from "js-file-download"

const ApiUrls = {
  Local: `http://127.0.0.1:3001/`,
  Stage: 'https://yritystieto-api-staging.peanutsgroup.com/',
  Production: 'https://yritystieto-api.peanutsgroup.com/',
}

let ApiUrl = ApiUrls.Production
if (window.location.hostname.includes('staging.peanutsgroup.com')) {
  ApiUrl = ApiUrls.Stage
}
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  ApiUrl = ApiUrls.Local
}

const create = (baseURL = ApiUrl) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      apikey: '0tce8R0Qpm1qICPuhq02oJqVdyZMghMBAqCrjyPF',
      "Content-Type": "application/json",
    },
    timeout: 15000,
    // withCredentials: true
  })

  const searchByValue = async (searchstring, filters) =>
    api.get(`widgets/search/${searchstring}`, { ...filters })

  const getRevenueList = async ({
    minRevenue = 1000000,
    maxRevenue = 100000000000000,
    region = "%",
    industry = "%",
    limit = 10,
    direction = "DESC",
    year = new Date().getFullYear(),
  }) =>
    api.get("widgets/revenue-list", {
      minRevenue,
      maxRevenue,
      region,
      industry,
      limit,
      direction,
      year,
    })

  const getEmployerList = async ({
    minRevenue = 1000000,
    maxRevenue = 100000000000000,
    region = "%",
    industry = "%",
    limit = 10,
    direction = "DESC",
    year = new Date().getFullYear(),
  }) =>
    api.get("widgets/employer-list", {
      minRevenue,
      maxRevenue,
      region,
      industry,
      limit,
      direction,
      year,
    })

  const getTaxList = async ({
    minRevenue = 1000000,
    maxRevenue = 100000000000000,
    region = "%",
    industry = "%",
    limit = 10,
    direction = "DESC",
    year = new Date().getFullYear(),
  }) =>
    api.get("widgets/tax-list", {
      minRevenue,
      maxRevenue,
      region,
      industry,
      limit,
      direction,
      year,
    })

  const getChartStatistics = async (region, industry, year) =>
    api.get("widgets/dashboard", { region, industry, year })
  const getRegionStatistics = async (region, industry, year) =>
    api.get("widgets/region-info", { region, industry, year })

  const getCompany = async businessId => api.get(`companies/${businessId}`)

  const getBranch = async branchId => api.get(`branches/${branchId}`)

  const getIndustries = async () => api.get("industries")

  const getSearchFilters = async () => api.get("widgets/search-filters")

  // TODO: Uncomment when needed
  // const downloadExcel = (keyword, search) => {
  //   const url = `${getBaseUrl()}companies/export-search-results/${keyword}${search}`
  //   axios.get(url, { headers: { apikey }, responseType: "blob" }).then(response => {
  //     fileDownload(response.data, "hakutiedot.xlsx")
  //   })
  // }

  const getFunding = async (region = "%", industry = "%", year = 2017) =>
    api.get("/widgets/funding-info", { region, industry, year })

  // Profinder industries
  const getProfinderIndustries = async () => api.get("companies/pf-industry-types")
  const getProfinderBranchIndustries = async () => api.get("branches/pf-industry-types")

  return {
    searchByValue,
    getRevenueList,
    getEmployerList,
    getTaxList,
    getChartStatistics,
    getRegionStatistics,
    getCompany,
    getBranch,
    getIndustries,
    getSearchFilters,
    getFunding,
    getProfinderIndustries,
    getProfinderBranchIndustries,
    // downloadExcel,
  }
}

const Api = create()

export default Api
