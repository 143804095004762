import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LoadingSpinner from "../components/LoadingSpinner"
import List from "../components/RevenueWidget/List"
import Styles from "./Top500.module.css"
import { scrolledToBottomListener } from "../util/handleScrollToBottom"
import { Api } from "../services"
import {
  REVENUE_VALUES,
  EMPLOYER_LIST_YEAR,
  TAX_LIST_YEAR,
  REVENUE_LIST_YEAR,
} from "../util/constants"
import { formatMoney } from "../util/string"
import { Seo } from "../components/Seo"

class Top500 extends Component {
  state = {
    fetching: false,
    data: [],
    rendable: [],
    pageTitle: '',
  }

  async componentDidMount() {
    const { match, employerParams, revenueParams, taxParams, filters } = this.props

    window.scrollTo(0, 0)

    let params
    this.setState({ fetching: true })

    if (match.params.listType === "tyollistajat") {
      params = employerParams
      const list = await Api.getEmployerList({
        ...params,
        year: filters.year !== 0 ? filters.year : EMPLOYER_LIST_YEAR,
        region: filters.region,
        industry: filters.industry,
        minRevenue: REVENUE_VALUES[params.revenue.length ? params.revenue - 1 : 0].min,
        maxRevenue: REVENUE_VALUES[params.revenue.length ? params.revenue - 1 : 0].max,
        limit: 500,
      })
      if (list.ok) {
        const data = list.data.map(d => ({ ...d, value: d.hlokeskim }))
        this.setState({ data, pageTitle: 'Top 500 työllistäjät' })
      }
    }
    if (match.params.listType === "kasvajat") {
      params = revenueParams
      const list = await Api.getRevenueList({
        ...params,
        year: filters.year !== 0 ? filters.year : REVENUE_LIST_YEAR,
        region: filters.region,
        industry: filters.industry,
        minRevenue: REVENUE_VALUES[params.revenue.length ? params.revenue - 1 : 0].min,
        maxRevenue: REVENUE_VALUES[params.revenue.length ? params.revenue - 1 : 0].max,
        limit: 500,
      })
      if (list.ok) {
        const data = list.data
          .sort((a, b) => b.lvkasvupros - a.lvkasvupros)
          .map(d => ({
            ...d,
            value: `${Number(d.lvkasvupros).toFixed(2)}%`,
          }))
          this.setState({ data, pageTitle: 'Top 500 liikevaihdon kasvattajat' })
        }
    }
    if (match.params.listType === "veronmaksajat") {
      params = taxParams
      const list = await Api.getTaxList({
        ...params,
        year: filters.year !== 0 ? filters.year : TAX_LIST_YEAR,
        region: filters.region,
        industry: filters.industry,
        minRevenue: REVENUE_VALUES[params.revenue.length ? params.revenue - 1 : 0].min,
        maxRevenue: REVENUE_VALUES[params.revenue.length ? params.revenue - 1 : 0].max,
        limit: 500,
      })
      if (list.ok) {
        const data = list.data.map(({ maksuunpannut_verot: taxes, ...rest }) => {
          const value = formatMoney(taxes)
          return {
            ...rest,
            value,
          }
        })
        this.setState({ data, pageTitle: 'Top 500 veronmaksajat' })
      }
    }
    this.setState({ fetching: false })
    this.appendRenderableItems(0, 50)
    this._scrollListener = scrolledToBottomListener(() => {
      const { rendable } = this.state
      this.appendRenderableItems(rendable.length, rendable.length + 50)
    })
    this._scrollListener.start()
  }

  componentWillUnmount = () => {
    this._scrollListener.stop()
  }

  appendRenderableItems = (start, end) => {
    const { data, rendable } = this.state
    this.setState({ rendable: rendable.concat(data.slice(start, end)) })
  }

  renderBackButton = () => {
    const { location } = this.props
    if (location.state && location.state.prevPath === "/") {
      return (
        <div className={Styles.backButton}>
          <Link
            to={{
              pathname: "/",
              state: { prevPath: location.pathname },
            }}
          >
            ← Takaisin etusivulle
          </Link>
        </div>
      )
    }
    return null
  }

  renderSpinner = () => {
    if (this.state.fetching) {
      return (
        <div className={Styles.loadingContainer}>
          <LoadingSpinner loading={this.state.fetching} />
        </div>
      )
    }
  }

  render() {
    const { rendable } = this.state
    const { location } = this.props
    return (
      <div className={Styles.top500container}>
        {this.renderBackButton()}
        <div className={Styles.listContainer}>
          {this.renderSpinner()}
          <List loading={false} list={rendable} location={location} />
        </div>
        <Seo title={this.state.pageTitle} />
      </div>
    )
  }
}

Top500.propTypes = {
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  revenueParams: PropTypes.shape().isRequired,
  employerParams: PropTypes.shape().isRequired,
  taxParams: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  revenueParams: state.revenueList.params,
  employerParams: state.employerList.params,
  taxParams: state.taxList.params,
  filters: state.filterOptions.filters,
})

export default connect(mapStateToProps)(Top500)
