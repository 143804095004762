import { Api } from "../services"

export const FETCH_FILTERS = "FETCH_FILTERS"
export const FETCH_FILTERS_OK = "FETCH_FILTERS_OK"
export const FETCH_FILTERS_ERROR = "FETCH_FILTERS_ERROR"
export const CHANGE_FILTERS = "CHANGE_FILTERS"
export const RESET_FILTERS = "RESET_FILTERS"

export const fetchSearchFilters = () => async dispatch => {
  dispatch({ type: FETCH_FILTERS })

  const response = await Api.getSearchFilters()

  if (response.ok) {
    const { data } = response
    const sortedIndustries =
      data.industryOptions && data.industryOptions.sort((a, b) => a.label.localeCompare(b.label))
    data.industryOptions = sortedIndustries
    dispatch({
      type: FETCH_FILTERS_OK,
      data,
    })
  } else {
    dispatch({
      type: FETCH_FILTERS_ERROR,
      error: response.data,
    })
  }
}

export const changeFilters = params => async dispatch => {
  dispatch({ type: CHANGE_FILTERS, filters: params })
}

export const resetFilters = () => async dispatch => {
  dispatch({ type: RESET_FILTERS })
}
