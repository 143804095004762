import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { fetchCompany } from "../actions/companyActions"
import { Info, Registry, Finances, RdiFinancing, Map } from "../components/CompanyInfo"
import LoadingSpinner from "../components/LoadingSpinner"
import Styles from "./Company.module.css"
import { Seo } from "../components/Seo"

class Company extends Component {
  static contextTypes = {
    router: () => true, // replace with PropTypes.object if you use them
  }

  state = {
    selectedYear: null,
    yearOptions: null,
  }

  componentDidMount() {
    const { match, getCompany } = this.props
    const { businessId } = match.params
    if (businessId) getCompany(businessId)
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    if (this.props.company !== prevProps.company) {
      this.getFinancialInfoYears()
    }
  }

  onChangeYear = selectedYear => {
    this.setState({ selectedYear })
  }

  getFinancialInfoYears = () => {
    const { company } = this.props
    const yearOptions = [...company.financialInfos]
      .sort((a, b) => a.tilinpaatos_vuosi - b.tilinpaatos_vuosi)
      .map((financialInfo, index) => ({ value: index, label: financialInfo.tilinpaatos_vuosi }))
    this.setState({
      selectedYear: yearOptions[yearOptions.length - 1],
      yearOptions,
    })
  }

  renderFinances = () => {
    const { company } = this.props
    const { selectedYear, yearOptions } = this.state
    if (company.financialInfos && company.financialInfos.length && selectedYear) {
      return (
        <Finances
          selectedYear={selectedYear}
          allFinances={[...company.financialInfos].sort(
            (a, b) => a.tilinpaatos_vuosi - b.tilinpaatos_vuosi
          )}
          yearOptions={yearOptions}
          onChange={this.onChangeYear}
        />
      )
    }
    return null
  }

  renderRdiFinancing = company => {
    if (!company || company.fundingInfos.length === 0) {
      return null
    }
    return <RdiFinancing company={company} />
  }

  renderMap = company => {
    if (!company.latitude && !company.branches?.some(b => b.latitude)) {
      return null
    }
    return <Map markers={[company]} showPopup={false} />
  }

  renderCompany = () => {
    const { company } = this.props
    if (Object.keys(company).length === 0) {
      return <h1 className={Styles.notFound}>Yritystä ei löydy</h1>
    }
    const hasTKI = company.fundingInfos && company.fundingInfos.length > 0
    const hasFinances = company.financialInfos && company.financialInfos.length > 0
    return (
      <div className={Styles.companyPage}>
        <Info company={company} />
        <div className={Styles.tabsContainer}>
          {!!company.yrityksen_tila && company.yrityksen_tila !== "-" && (
            <Registry company={company} />
          )}
          {hasFinances && this.renderFinances()}
          {hasTKI && <div>{this.renderRdiFinancing(company)}</div>}
        </div>
        <Seo title={company.virallinen_nimi} />
        {this.renderMap(company)}
      </div>
    )
  }

  renderBackButton = () => {
    const { location, history } = this.props
    if (location.state && location.state.prevPath === "/") {
      return (
        <div className={Styles.backButton}>
          <Link
            to={{
              pathname: "/",
              state: { prevPath: location.pathname },
            }}
          >
            ← Takaisin etusivulle
          </Link>
        </div>
      )
    }
    if (
      (location.state && location.state.prevPath.includes("/haku/")) ||
      (location.state && location.state.prevPath.includes("/top500/"))
    ) {
      return (
        <div className={Styles.backButton}>
          <button type="button" onClick={history.goBack}>
            ← Takaisin hakuun
          </button>
        </div>
      )
    }
    if (document.referrer.includes(window.location.origin)) {
      return (
        <div className={Styles.backButton}>
          <button type="button" onClick={history.goBack}>
            ← Takaisin
          </button>
        </div>
      )
    }
    return null
  }

  render() {
    const { loading } = this.props

    return (
      <div className={[Styles.pageContent, Styles.companyScreen].join(" ")}>
        {loading ? (
          <div className={Styles.loadingContainer}>
            <LoadingSpinner loading={loading} />
          </div>
        ) : (
          <div>
            {this.renderBackButton()}
            {this.renderCompany()}
          </div>
        )}
      </div>
    )
  }
}

Company.propTypes = {
  loading: PropTypes.bool.isRequired,
  // TODO: specify actual object shape (if necessary)
  company: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  getCompany: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

Company.contextTypes = {
  router: PropTypes.shape(),
}

function mapStateToProps(state) {
  return {
    company: state.company.data,
    loading: state.company.loading,
  }
}

const mapDispatchToProps = dispatch => ({
  getCompany: businessId => dispatch(fetchCompany(businessId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company))
